import React from 'react'
import { useWindowSize, useNavigation } from 'msp-integrations'
import {
  DynamicTag,
  Headline,
  RichTextComponent,
  Icons,
  Button,
  Image
} from 'msp-components'
import * as S from './FullWidthIntro.styles'
import { FullWidthIntroProps } from './FullWidthIntro.types'
import { twMerge } from 'tailwind-merge'
import { getOverlayStyle, getPosition } from './FullWidthIntro.utils'

const FullWidthIntro = ({
  bgImage,
  imageAlt = '',
  contentPosition,
  preline,
  headline,
  subHeadline,
  contentRichText,
  buttonLink,
  buttonText,
  bannerRichText,
  bgColor,
  overlay,
  imageWrapperPaddingRatio
}: FullWidthIntroProps) => {
  const navigate = useNavigation()
  const textColor =
    overlay === 'base' || overlay === 'hero' || bgColor === 'green'
      ? 'white'
      : 'black'
  const overlayStyle = getOverlayStyle(overlay)
  const position = getPosition({ overlay, contentPosition })
  const { width, breakpoints } = useWindowSize()
  const isIpad = width && width >= breakpoints.md
  let logoIconHeight = '125px'
  let socialIconWidth = '40px'
  if (isIpad) {
    socialIconWidth = '28px'
  }

  const imageWrapper = (
    <div
      className={`relative overflow-hidden ${imageWrapperPaddingRatio} ${overlayStyle.imageWrapper}`}
    >
      <Image
        className={`${overlayStyle.image} h-full w-full object-cover`}
        src={bgImage}
        alt={imageAlt}
        loading='eager'
        fetchPriority='high'
        forceScale
      />
    </div>
  )

  return (
    <div className='relative'>
      <div className={`${overlayStyle.mainWrapper} relative h-auto w-full`}>
        {overlay !== 'service' && imageWrapper}
        <div
          className={`${overlayStyle.contentWrapper} ${
            overlay === 'service'
              ? overlayStyle.contentBackgroundColor[bgColor]
              : ''
          } `}
        >
          <div
            className={`flex flex-col ${overlayStyle.contentContainer} ${position}`}
          >
            {preline && (
              <DynamicTag
                componentTagName='Preline'
                className={`text-${textColor}`}
              >
                {preline || ''}
              </DynamicTag>
            )}
            <Headline
              tag='h1'
              className={`${overlayStyle.headline} leading-normal text-${textColor}`}
            >
              {headline}
            </Headline>
            {subHeadline && (
              <RichTextComponent
                data={{
                  richText: subHeadline,
                  textColor: textColor
                }}
                className={`${overlayStyle.subHeadline} text-20`}
              />
            )}
            {contentRichText && (
              <div
                className={
                  overlay === 'base'
                    ? S.scrollableRichText
                    : overlayStyle.richText
                }
              >
                <RichTextComponent
                  data={{
                    richText: contentRichText,
                    textColor: textColor
                  }}
                />
              </div>
            )}
            {buttonText && buttonLink && (
              <Button
                className={twMerge(
                  `${S.ctaButton} ${
                    overlay === 'hero' ? 'mb-5 mt-5 lg:mb-0 lg:mt-10' : ''
                  }`
                )}
                onClick={() => navigate(buttonLink)}
                isDarkMode={overlay === 'base'}
                type='primary'
              >
                <p>{buttonText}</p>
              </Button>
            )}
          </div>
        </div>
        {overlay === 'service' && imageWrapper}
      </div>
      {bannerRichText && (
        <div className={S.aboutOverlay.bottomBanner.container}>
          <div className={S.aboutOverlay.bottomBanner.logo}>
            <Icons iconName='AIcon' width={'auto'} height={logoIconHeight} />
          </div>
          <div className={S.aboutOverlay.bottomBanner.content}>
            <RichTextComponent
              className={S.aboutOverlay.bottomBanner.richText}
              data={{
                richText: bannerRichText,
                textColor: textColor
              }}
            />
            <div className={S.aboutOverlay.bottomBanner.socials}>
              <div className='mr-2 w-10 lg:w-7'>
                <Icons iconName='Facebook' width={socialIconWidth} />
              </div>
              <div className='mr-2 w-10 lg:w-7'>
                <Icons iconName='Instagram' width={socialIconWidth} />
              </div>
              <div className='mr-2 w-10 lg:w-7'>
                <Icons iconName='Pinterest' width={socialIconWidth} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default FullWidthIntro
