"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _useClient = require("./useClient");
Object.keys(_useClient).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useClient[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _useClient[key];
    }
  });
});
var _useQuery = require("./useQuery");
Object.keys(_useQuery).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useQuery[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _useQuery[key];
    }
  });
});
var _useMutation = require("./useMutation");
Object.keys(_useMutation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useMutation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _useMutation[key];
    }
  });
});
var _useSfraApi = require("./useSfraApi");
Object.keys(_useSfraApi).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useSfraApi[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _useSfraApi[key];
    }
  });
});
var _useSfScApi = require("./useSfScApi");
Object.keys(_useSfScApi).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useSfScApi[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _useSfScApi[key];
    }
  });
});
var _sfraApi = require("./sfra-api");
Object.keys(_sfraApi).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _sfraApi[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _sfraApi[key];
    }
  });
});
var _useConfig = require("./useConfig");
Object.keys(_useConfig).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useConfig[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _useConfig[key];
    }
  });
});
var _useCookie = require("./useCookie");
Object.keys(_useCookie).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useCookie[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _useCookie[key];
    }
  });
});
var _useEffectSkipFirst = require("./useEffectSkipFirst");
Object.keys(_useEffectSkipFirst).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useEffectSkipFirst[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _useEffectSkipFirst[key];
    }
  });
});